<template>
    <UiEmailSubscription id="SbEmailSubscription" :blok="props.blok" :apr="false" @submit="subscribeEmail" />
</template>

<script setup lang="ts">
import { useEmailSubscriptionStore } from '../../_base/store/emailSubscription';
import type { IEmailSubscriptionConfig } from '@nuxt-monorepo/ui/types';

const props = defineProps<{ blok: IEmailSubscriptionConfig }>();
const emailSubscriptionStore = useEmailSubscriptionStore();
const backendError = ref('');
const config = useRuntimeConfig();
provide('backendError', backendError);

const subscribeEmail = async ({ email }) => {
    return await $fetch(`/api/subscribe`, {
        method: 'POST',
        body: { email, listId: config?.public.ITERABLE_LIST_ID, signupSource: props.blok.signupSource ?? '' },
        onResponse({ response }) {
            if (response.ok) {
                emailSubscriptionStore.updateIsSubscribed(true);
                backendError.value = '';
            }
        },
        onResponseError({ response }) {
            if (response) {
                backendError.value = response._data.message;
            }
        },
    }).catch((err) => {
        backendError.value = backendError.value || err;
    });
};
</script>

<style lang="postcss" scoped>
#SbEmailSubscription {
    @apply text-primary;
    :deep(.container) {
        @apply flex-col;
        h3 {
            @apply text-[32px] leading-10 text-primary text-center font-bold;
        }

        .success,
        .validation-error {
            @apply hidden;
        }

        p {
            @apply not-italic font-normal text-base leading-6 relative text-primary pb-4 mb-8 text-center;
            &:before,
            &:after {
                content: '';
                @apply absolute left-1/2 bottom-0 bg-primary -translate-x-1/2;
            }
            &:before {
                @apply w-9 h-[3px] -bottom-[1px];
            }
            &:after {
                @apply w-[164px] h-[1px];
            }
        }

        .error-tooltip {
            @apply md:absolute mt-2 px-2 !bg-transparent text-[#ff3738] text-left md:bottom-auto md:top-full md:h-max text-sm;
        }

        .input-wrapper {
            @apply relative w-full md:max-w-full max-w-[400px] flex justify-center;
        }

        form {
            @apply flex-col md:flex-row gap-6 relative w-[640px];
            &:before {
                @apply hidden;
            }
            input {
                @apply gap-2 md:max-w-[480px] w-full h-10 border px-3 py-2 rounded-lg border-solid border-[#bec1d2] pl-2 basis-auto transition-all duration-[0.33s] ease-[ease-in-out];
                &::placeholder {
                    @apply font-normal text-base leading-6 text-[#BEC1D2];
                }

                &.has-error {
                    @apply border-[#ff3738];
                }
            }

            button {
                @apply gap-2 text-xs w-full md:max-w-[148px] h-10 bg-primary px-10 rounded-lg border-2 border-solid border-[hsl(202,93%,28%)] basis-auto p-0 font-bold;
            }
        }
    }
}
</style>
